import { getDomains } from '@paperstac/helpers/lib/domain';
import { AccountFirestoreData } from '@paperstac/types/lib/Account';
import firebase from 'firebase/compat';
import { collection, query, where } from 'firebase/firestore';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import useLocalStorageState from 'use-local-storage-state';
import { auth, firestore } from '../services/firebase';
import FirestoreError = firebase.firestore.FirestoreError;

type CurrentAccountsContextValue = {
  currentAccounts: AccountFirestoreData[] | undefined;
  currentAccountsError: FirestoreError | undefined;
  currentAccountsLoading: boolean;
  currentAccount: AccountFirestoreData | undefined;
  currentAccountId: string;
  setCurrentAccountId: (currentAccountId: string) => void;
};

const CurrentAccountsContext = React.createContext<CurrentAccountsContextValue>({
  currentAccounts: undefined,
  currentAccountsError: undefined,
  currentAccountsLoading: true,
  currentAccount: undefined,
  currentAccountId: '',
  setCurrentAccountId: () => {},
});

const CurrentAccountsProvider = React.memo((props) => {
  const [authUser] = useAuthState(auth);
  const [currentAccountId, setCurrentAccountId] = useLocalStorageState<string>('currentAccountIdState', {
    defaultValue: '',
  });

  const currentAccountsQuery = authUser
    ? query<AccountFirestoreData, AccountFirestoreData>(
        collection(firestore, 'accounts') as any,
        where(`members.${authUser.uid}.id`, '==', authUser.uid),
        where('domain', '==', getDomains().NOTECLOSINGS)
      )
    : null;
  const [currentAccounts, currentAccountsLoading, currentAccountsError] = useCollectionData(currentAccountsQuery);

  const providerValue = React.useMemo<CurrentAccountsContextValue>(
    () => ({
      currentAccounts,
      currentAccountsError,
      currentAccountsLoading,
      currentAccount:
        !currentAccounts || currentAccountId === ''
          ? undefined
          : currentAccounts.find((a) => a.id === currentAccountId),
      currentAccountId,
      setCurrentAccountId,
    }),
    [currentAccounts, currentAccountsError, currentAccountsLoading, currentAccountId, setCurrentAccountId]
  );

  React.useEffect(() => {
    // If user is logged out, reset the current account
    if (!authUser) {
      setCurrentAccountId('');
    }
  }, [authUser, setCurrentAccountId]);

  React.useEffect(() => {
    if (!currentAccounts || currentAccountsLoading) return;

    if (currentAccounts.length === 0 && !!currentAccountId) {
      // User does not have any accounts, reset the current account
      setCurrentAccountId('');
    } else if (currentAccounts.length === 1 && currentAccounts[0].id !== currentAccountId) {
      // User has only one account, auto-select it
      setCurrentAccountId(currentAccounts[0].id);
    } else if (
      currentAccounts.length >= 2 &&
      !!currentAccountId &&
      !currentAccounts.some((a) => a.id === currentAccountId)
    ) {
      // User does not belong to current account, reset the current account
      setCurrentAccountId('');
      return;
    }
  }, [currentAccountId, currentAccounts, currentAccountsLoading, setCurrentAccountId]);

  if (currentAccountsLoading) return null;

  return <CurrentAccountsContext.Provider value={providerValue} {...props} />;
});

CurrentAccountsProvider.displayName = 'CurrentAccountsProvider';

export default CurrentAccountsProvider;

export function useCurrentAccounts(): AccountFirestoreData[] | undefined {
  const { currentAccounts } = React.useContext(CurrentAccountsContext);
  return currentAccounts;
}

export function useCurrentAccount(): AccountFirestoreData | undefined {
  const { currentAccount } = React.useContext(CurrentAccountsContext);
  return currentAccount;
}

export function useCurrentAccountId(): string {
  const { currentAccountId } = React.useContext(CurrentAccountsContext);
  return currentAccountId;
}

export function useSetCurrentAccountId(): (currentAccountId: string) => void {
  const { setCurrentAccountId } = React.useContext(CurrentAccountsContext);
  return setCurrentAccountId;
}
